import React from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/layout";
import ArrowRightIcon from '@material-ui/icons/ArrowForward'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SEO from "../components/seo";
import {SearchWrapper} from "../components/learning-library/search-wrapper";
import BreadCrumbs from "../components/bread-crumbs";
import './topic-details.scss';

var Loader = require('react-loader');
export default (props) => {
    const {data, pageContext} = props;
    let articlesData;
    let categoriesName = [];
    let allTopics = [];
    categoriesName = data.allContentfulCategory.edges.map((categories) => {
        return categories.node;
    });
    const mySelectedCategory = categoriesName
        .filter((selectedCategory) => {
            return selectedCategory.slug === pageContext.category.slug
                && selectedCategory.topics !== null;
        });
    allTopics = mySelectedCategory[0].topics.filter((topic) => {
        return topic.publishOnWebsite === true;
    });
    const myselectedTopic = allTopics.filter((selectedTopic) => {
        return selectedTopic.slug === pageContext.slug
            && selectedTopic.educationOrder
    });
    articlesData = myselectedTopic[0]
        && myselectedTopic[0].educationOrder.filter(function (item) {
            return item.publishOnWebsite;
        });
    const breadCrumbsList  = [
        {
            title: "Home",
            link: "/",
        },
        {
            title: "Learning Library",
            link: "/learning-library",
        },
        {
            title: pageContext.category?.name,
            link: `/${pageContext.category?.slug}`,
        }

    ]
    return (
        <Layout location={props.location}>
            <SEO title={pageContext.slug}/>
            <div className="bread-crumbs-for-mobile">
                <div className="crumbs-inner">
                    <div className="crumbs-side">
                        <div className="bread-crumb-inner">
                            <a href={'/learning-library/'}>Learning Library</a>
                            <ArrowRightIcon className="arrow"/>
                        </div>
                        <div className="bread-crumb-inner">
                            <a href={`/${pageContext.category?.slug}`}
                               className="crumbs-text"> {pageContext.category?.name}</a>
                            <ArrowRightIcon className="arrow"/>
                        </div>
                        <div className="bread-crumb-inner">
                            <a className="crumbs-text">{pageContext.slug}</a>
                            <ArrowRightIcon className="arrow hidden"/>
                        </div>
                    </div>
                    <div className="share-box">
                        <a className="share-link"
                           href="https://facebook.com/confidanthealth"
                           target="_blank"
                           rel="noopener noreferrer">
                            <img src={require('../assets/images/fb.svg')}
                                 alt="Facebook"/>
                        </a>
                        <a className="share-link"
                           href="https://twitter.com/confidanthealth"
                           target="_blank"
                           rel="noopener noreferrer">
                            <img src={require('../assets/images/twitter.svg')}
                                 alt="Twitter"/>
                        </a>
                    </div>
                </div>
            </div>
{/*            <div className="bread-crumbs">
                <div className="crumbs-inner">
                    <div className="crumbs-side">
                        <a className="crumbs-text" href={'/'}>
                            Home</a>
                        <ArrowRightIcon/>
                        <a className="crumbs-text" href={'/learning-library/'}>
                            Learning Library</a>
                        <ArrowRightIcon/>
                        <a href={`/${pageContext.category?.slug}`}
                           className="crumbs-text"> {pageContext.category?.name}</a>
                        <ArrowRightIcon/>
                        <p className="crumbs-text-black">{pageContext.name}</p>
                    </div>
                </div>
            </div>*/}
            <BreadCrumbs currentPage={pageContext.name} breadCrumbsList={breadCrumbsList}/>
            <div className="new-topic-body">
                <div className="left-side-box">
                    <div className="left-icon-box">
                        <img src={require("../assets/images/help_FnF.svg")}
                             alt="Icon"/>
                    </div>
                    <FormControl variant="filled" className="drugs-dropdown">
                        <InputLabel
                            id="service-label">{pageContext.category.name}</InputLabel>
                        <Select
                            labelId="service-label"
                            id="service-select"
                            value=""
                            label="Select Service"
                        >
                            {
                                categoriesName.map((item) => {
                                    return (
                                        <MenuItem className="data-dropDown"
                                                  value={item.slug}>
                                            <Link className="topic-link"
                                                  to={`/${item.slug}/`}>{item.name}</Link>
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    <div className="topic-list-box">
                        <ul className="left-topic-list">
                            {
                                allTopics.map((item) => {
                                    return (
                                        <ul className="left-article-list">
                                            <li>
                                                <Link className="topic-click"
                                                      to={`/${pageContext.category?.slug}/${item.slug}/`}>{item.name}</Link>
                                            </li>
                                        </ul>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="right-side-box">
                    <h2 className="blackHeading2"
                        id={'main-Heading'}>{pageContext.name}</h2>
                    <SearchWrapper topic={pageContext.slug}/>
                    <div className="new-top-article-box">
                        <div className="article-list">
                            {
                                articlesData ?
                                    articlesData.length > 0 ?
                                        articlesData.map((items) => {
                                            return (
                                                <div
                                                    className="popular-article">
                                                    {items.titleImage !== null ?
                                                        <img
                                                            className="topic-icon"
                                                            src={`${items.titleImage.file.url}`}
                                                            alt="Topic Icon"/>
                                                        :
                                                        <img
                                                            className="topic-icon"
                                                            src={require(
                                                                '../assets/images/article-img2.png')}
                                                            alt="Topic Icon"/>
                                                    }
                                                    <div
                                                        className="article-content">
                                                        <h3 className="heading3">{items.title.title}</h3>
                                                        <p className="parah">{items.description}</p>
                                                    </div>
                                                    <div
                                                        className="article-top">
                                                        <p className="article-time">{items.contentLengthduration}</p>
                                                        <Link
                                                            to={`/${pageContext.category?.slug}/${pageContext?.slug}/${items.slug}/`}
                                                            className="article-link">
                                                            Read article
                                                        </Link>
                                                    </div>
                                                </div>
                                            )
                                        }) : <p>No Question Found </p> : <Loader
                                        loaded={false}/>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    );
}
export const query = graphql`
query topicsCategoryById {
    allContentfulCategory {
      edges {
        node {
          id
          name
          slug
          topics {
            id
            name
            slug
            coverImage {
                file {
                  url
                }
              }
              publishOnWebsite
            educationOrder {
              id
              description
              title {
                title
              }
              contentLengthduration
              publishOnWebsite
              slug
              titleImage {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
  `;


